import { useEffect, useRef, useState } from 'react';
import SearchIcon from '../../icons/SearchIcon';
import SearchAtom from '../../atoms/SearchAtom';
import RecentSearchesAtom from '../../atoms/RecentSearchesAtom';
import { useRecoilState } from 'recoil';
import './Search.scss';

const Search = () => {
    const ref = useRef();
    const [value, setValue] = useState('');
    const [search, setSearch] = useRecoilState(SearchAtom);
    const [recentSearches, setRecentSearches] = useRecoilState(RecentSearchesAtom);

    let valueWatcher = useRef();
    let oldValue = useRef(value);

    useEffect(() => {
        if (valueWatcher.current) clearTimeout(valueWatcher.current);
        valueWatcher.current = setTimeout(() => {
            let newValue = value.toLowerCase().trim();
            if (newValue !== oldValue) setSearch(newValue);
            oldValue.current = newValue

        }, 250);

        return () => clearTimeout(valueWatcher.current);
    }, [value]);

    const addToRecentSearches = () => {
        if (!search) return;
        let recents = [...recentSearches];
        recents.unshift(search);
        setRecentSearches([...new Set(recents)].slice(0, 3));
    }

    useEffect(() => {
        localStorage.setItem("paysave_recentSearches", JSON.stringify(recentSearches));
    }, [recentSearches]);

    const clear = () => {
        setValue('');
        setSearch('');
        ref.current.focus();
    }

    const handleKeyDown = (e) => {
        if (e.repeat) return;

        switch (e.key) {
            case "Enter":
                addToRecentSearches();
                blur();
                break;
            case "Escape":
                blur();
                break;
            default:
                break;
        }

    }

    const blur = () => {
        ref.current.blur();
    }

    return (
        <div className="search-wrapper">
            <div className="search-icon">
                <SearchIcon />
            </div>
            <input
                type="text"
                placeholder="Records, Names"
                className='search-input'
                ref={ref}
                value={value}
                name='search'
                inputMode='search'
                enterKeyHint="search"
                onKeyDown={handleKeyDown}
                onChange={(e) => { setValue(e.target.value) }}
                list="recent-searches"
            />
            <datalist id="recent-searches">
                {recentSearches.map((search) => (
                    <option value={search} key={search} />
                ))}
            </datalist>
            {value ? (
                <button
                    className='search-clear-btn'
                    onClick={clear}
                    title="Clear search"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" opacity=".87" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" /></svg>
                </button>
            ) : null}
        </div>
    )
}

export default Search